import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LogoutButton from '../components/LogoutButton/LogoutButton'
/* 👇 Import the withAuthenticationRequired HOC 👇 */ 
import { withAuthenticationRequired } from '@auth0/auth0-react'

// Components
import Layout from '../components/Layout/layout'
import Card from '../components/Card/card'
import SEO from '../components/SEO/seo'

// Mock Data
import stoneData from '../utils/stones.json'

const STONE_SHAPES = [
    'Asscher',
    'Cushion',
    'Elongated Cushion',
    'Emerald',
    'Heart',
    'Marquise',
    'Oval',
    'Pear',
    'Princess',
    'Radiant',
    'Round',
    'Trilliant',
]

const INVENTORY_SOURCES = [
    'TDP',
    'VDB'
]

class StonesPage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            inventory: stoneData.data,
            loading: false,
            jobLoading: false,
            stone: 'Round',
            size: 1,
            source: 'TDP',
            exactSize: false,
            modal: false,
            modalId: null,
            jobList: null, 
            jobListId: null,
            stoneToBeAddedLoader: false,
            stoneToBeAddedPayload: null,
            showSnackbar: false,
            snackbarMessage: null,
            avgPrice: 0
        }
    }

    componentDidMount(){
        const urlSearchParams = new URLSearchParams(window.location.search)
        const params = Object.fromEntries(urlSearchParams.entries())

        if (params.stone && params.size){
            this.__fetchData(params.stone, params.size, params.source)
        }

    }

    addToAirtable = (stone) => {
        this.setState({modal: true, modalId: stone.STOCK_ID || stone.stock_num, stoneToBeAddedPayload: stone })
        this.__fetchJobs()
    }

    closeModal(){
        this.setState({modal: false, modalId: null, stoneToBeAddedPayload: null, jobListId: null})
    }

    __ratioCalculator = (length, width) => {
        let l = parseFloat(length)
        let w = parseFloat(width)
        if (l < w){
            return w/l
        }

        return l/w
    }

    __fetchJobs(){
        this.setState({jobLoading: true})
        try {
            const response = fetch(`https://fw-app-dev-bling-api.azurewebsites.net/api/v1/jobs`, {
                crossDomain: true,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'localhost:8080',
                    'Authorization': `${process.env.FW_API_KEY}`
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status === 'error' || res.code === 500){
                        this.setState({loading: false, jobList: null})
                    } else {
                        this.setState({loading: false, jobList: res.data})
                    }
                })
                .catch((error) => {
                    console.error(error)
                    this.setState({loading: false, jobList: null})
                })

            return response

        } catch(error) {
            console.log(error)
        }

    }

    __calculateAveragePrice(inventory){
        let totalPrice = inventory.reduce(function (acc, obj) { return acc + (Number(obj.TOTAL_PRICE || obj.total_sales_price)) }, 0)

        return Math.round((totalPrice/inventory.length)/this.state.size)
    }

    __submitStoneToAirTable(){

        //FIXME: Add Validation if any of the data is missing => otherwise this will break;
        const payload = {
            rowId: this.state.jobListId,
            stockId: this.state.stoneToBeAddedPayload.STOCK_ID || this.state.stoneToBeAddedPayload.stock_num,
            supplier: this.state.stoneToBeAddedPayload.STOCK_ID ? 'The Diamond Port' : this.state.stoneToBeAddedPayload.vendor_name,
            shape: this.state.stoneToBeAddedPayload.SHAPE || this.state.stoneToBeAddedPayload.shape,
            cut: this.state.stoneToBeAddedPayload.CUT || this.state.stoneToBeAddedPayload.cut,
            carat: this.state.stoneToBeAddedPayload.CARAT || this.state.stoneToBeAddedPayload.size,
            colour: this.state.stoneToBeAddedPayload.COLOR || this.state.stoneToBeAddedPayload.color,
            clarity: this.state.stoneToBeAddedPayload.CLARITY || this.state.stoneToBeAddedPayload.clarity,
            lab: this.state.stoneToBeAddedPayload.LAB || this.state.stoneToBeAddedPayload.lab,
            igiNumber: this.state.stoneToBeAddedPayload.CERTIFICATE_NO || this.state.stoneToBeAddedPayload.cert_num,
            videoUrl: this.state.stoneToBeAddedPayload.VIDEO || this.state.stoneToBeAddedPayload.video_url,
            price: this.state.stoneToBeAddedPayload.TOTAL_PRICE || this.state.stoneToBeAddedPayload.total_sales_price
        }

        try {
            const response = fetch(`https://fw-app-dev-bling-api.azurewebsites.net/api/v1/jobs/${this.state.jobListId}`, {
                crossDomain: true,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'localhost:8080',
                    'Authorization': `${process.env.FW_API_KEY}`
                },
                body: JSON.stringify(payload)
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status === 'error' || res.code === 500){
                        console.log('Error')
                    } 

                    this.setState({modal: null, modalId: null, stoneToBeAddedLoader: null, stoneToBeAddedPayload: null, jobListId: null, showSnackbar: true, snackbarMessage: 'Stone added to Airtable'})
                    setTimeout(() => {this.setState({showSnackbar: false})}, 3000)
                })
                .catch((error) => {
                    console.error(error)
                    console.log('Error')
                })

            return response
        }catch(error) {
            console.log(error)
        }
    }

    __fetchData(stone, size, source, exactSize){
        this.setState({loading: true})

        if(stone && size){
            try {
                const response = fetch(`https://fw-app-dev-bling-api.azurewebsites.net/api/v1/inventory?stone=${stone}&size=${size}${source ? `&source=${source}`:''}${exactSize ? `&exact=true` : ''}`, {
                    crossDomain: true, 
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': 'localhost:8080',
                        'Authorization': `${process.env.FW_API_KEY}`
                    }
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.status === 'error' || res.code === 500){
                            this.setState({loading: false, inventory: null})
                        } else {
                            this.setState({loading: false, inventory: res.data})
                        }
    
                    })
                    .catch((error) => {
                        console.error(error) 
                        this.setState({loading: false, inventory: null})
                    })
    
                return response
    
            } catch (error) {
                console.error(error)
            }
        } else {
            this.setState({loading: false, inventory: null})
        }
    }
    
    render(){
        const {user} = useAuth0()

        return <>
            <Layout>
                <SEO title={'Inventory'}/>
                <div className='container'>
                    <div className='row'>
                        <section className='col-sm mt-3 pt-3'>
                            <div className='d-flex flex-wrap justify-content-between mb-3'>
                                <h1>Inventory Tool</h1>
                                <div className='d-flex align-items-center'>
                                    <p className='mb-0 mr-3'>{user.name}</p>  
                                    <LogoutButton/>
                                </div>
                            </div>

                            <div className='d-flex flex-wrap'>
                                <div className='d-flex flex-wrap'>
                                    <label className='d-flex flex-column pr-3'>
                                    Shape
                                        <select onChange={(event) => this.setState({stone: event.target.value})}>
                                            <option value=''></option>
                                            {
                                                STONE_SHAPES.map((item, index) => (
                                                    <option key={index} value={item} selected={item === 'Round' ? true : false}>{item}</option>
                                                ))
                                            }
                                        </select>
                                    </label>

                                    <label className='d-flex flex-column px-3'>
                                        Carat
                                        <input type={'number'} value={this.state.size} onChange={(event) => this.setState({size: event.target.value})}/>
                                    </label>
                                    <label className='d-flex flex-column px-3'>
                                    Inventory Source
                                        <select onChange={(event) => this.setState({source: event.target.value})} defaultValue={'TDP'}>
                                            <option value=''>All</option>
                                            {
                                                INVENTORY_SOURCES.map((item, index) => (
                                                    <option key={index} value={item}>Only {item}</option>
                                                ))
                                            }
                                        </select>
                                    </label>
                                </div>

                                <button className='btn btn-primary' onClick={() => this.__fetchData(this.state.stone, this.state.size, this.state.source, this.state.exactSize)}>Get Inventory</button>
                                {
                                    this.state.loading ? 
                                        <p>Loading Data</p>
                                        :
                                        null
                                }
                            </div>
                            <hr />
                            {this.state.inventory && <div className='mb-3'>{this.state.inventory.length} results</div>} 
                            {/* <div>Average Price: {this.__calculateAveragePrice(this.state.inventory)}</div> */}
                        </section>
                        <section className='row px-2'>
                            {
                                this.state.inventory && this.state.inventory.filter((_elm) => this.__ratioCalculator(_elm.LENGTH || _elm.meas_length, _elm.WIDTH || _elm.meas_width) <= 2).map((stone, index) => {
                                    let newStone = stone.SHAPE || stone.shape
                                    let pricePerCt = Number.parseFloat(stone.PRICE_PER_CTS || stone.price_per_carat).toFixed(0)
                                    let concatTitle = `${newStone} ${stone.CARAT || stone.size} ${stone.COLOR || stone.color} ${stone.CLARITY || stone.clarity}`
                                    return (
                                        <div className='col-6 col-md-4 col-lg-3' key={index}>
                                            <Card 
                                                id={index}
                                                image={stone.IMAGE || stone.image_url}
                                                video={stone.VIDEO || stone.video_url}
                                                stockId={stone.STOCK_ID || stone.stock_num}
                                                supplierName={stone.STOCK_ID ? 'The Diamond Port' : stone.vendor_name}
                                                supplierContactEmail={stone.vendor_email}
                                                supplierLocationDetails={stone.location}
                                                stoneShape={newStone.toLowerCase()}
                                                stoneSize={stone.CARAT || stone.size}
                                                title={concatTitle}
                                                reportLab={stone.LAB || stone.lab}
                                                reportNumber={stone.CERTIFICATE_NO || stone.cert_num}
                                                cut={stone.CUT || stone.cut}
                                                polish={stone.POLISH || stone.polish}
                                                symmetry={stone.SYMMETRY || stone.symmetry}
                                                price={stone.TOTAL_PRICE || (stone.total_sales_price || stone.base_price)}
                                                pricePerCt={pricePerCt}
                                                length={stone.LENGTH || stone.meas_length}
                                                width={stone.WIDTH || stone.meas_width}
                                                depth={stone.DEPTH || stone.meas_depth}
                                                depthPercentage={stone.DEPTH_PER || stone.depth_percent}
                                                tablePercentage={stone.TABLE_PER || stone.table_percent}
                                                addToAirtable={() => this.addToAirtable(stone)}
                                                pavilionAngle={stone.PAVILIONANGLE || stone.pavilion_angle}
                                                crownAngle={stone.CROWNANGLE || stone.crown_angle}
                                                crownHeight={stone.CROWNHEIGHT || stone.crown_height}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </section>
                    </div>
                </div>
                {
                    this.state.modal && 
                    <>
                        <div className='modal-overlay'>
                            <figure className='modal w-100' style={{maxWidth: 600}}>
                                <div className='modal__header'>
                                    <p className='h2 m-0'>Add Stone to Airtable</p>
                                    <button className='btn btn-outline-primary' onClick={() => this.closeModal()}>X</button>
                                </div>
                                <hr className='w-100'/>
                                <div className='modal__body form'>
                                    {
                                        this.setState.jobLoading ?
                                            <p>Loading...</p>
                                            :
                                            <div className='form-group' >

                                                <label htmlFor='jobSelectControl' className='m-0 d-block'><strong>Job List</strong></label>
                                                <small><em>Pick which customer / job to assign this stone to.</em></small>
                                                <select className='form-control' id='jobSelectControl' onChange={(event) => this.setState({jobListId: event.target.value})}>
                                                    <option value=''></option>
                                                    {
                                                        this.state.jobList && this.state.jobList.map((job) => {
                                                            return (
                                                                <option value={job.id} key={job.id}>
                                                                    {job.fields.orderId}
                                                                    &nbsp;| &nbsp;
                                                                    {job.fields['Full Job Name'] ?  job.fields['Full Job Name'][0] : null} 
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>

                                    }

                                    <hr/>
                                </div>
                                <div className='modal__footer d-flex justify-content-between'>
                                    <button className='btn btn-outline-primary' onClick={() => this.closeModal()}>Close</button>
                                    <button className='btn btn-primary' disabled={!this.state.jobListId} onClick={() => this.__submitStoneToAirTable()}>Add Stone</button>
                                </div>
                            </figure>
                        </div>

                    </>
                }
                {
                    this.state.showSnackbar && 
                    <div className='snackbar'>
                        <p className='m-0'>{this.state.snackbarMessage}</p>
                        <button className='btn btn-outline-primary ml-3 py-2' onClick={() => this.setState({showSnackbar: false})}>Dismiss</button>
                    </div>
                }

            </Layout>
        </>
    }
}

export default withAuthenticationRequired(StonesPage)